import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component } from 'vue-property-decorator'
import router from '@/bootstrap/router/router'
import Container from 'typedi'
import EventService from '@/modules/common/services/event/event.service'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrLeadsView'
})
export default class GtrLeadsView extends GtrSuper {
  data () {
    return {
      loading: false,
      table: {
        search: '',
        headers: [
          { text: 'Exhibitor', align: 'start', sortable: true, value: 'search_data', width: '100' },
          { text: 'Scan Timestamp', align: 'start', sortable: true, searchable: false, value: 'scan_time', width: '200' }
        ],
        items: []
      },
      event_uuid: this.$route.params.event_uuid,
      from: this.$route.meta?.from,
      from_route: this.$route.meta?.from_route,
      participant: {
        uuid: this.$route.params.participant_uuid
      },
      qualifiers: []
    }
  }

  async exportLeadsForExhibitor () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportLeadsForExhibitor', { event_uuid: this.$route.params.event_uuid, participant_uuid: this.$route.params.participant_uuid })
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.LEADS.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Leads Module not activated. Please, activate it and try again...'
        router.push({
          name: 'level-two.event.modules',
          params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid }
        }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }

  async mounted () {
    await this.fetchScansByParticipant(this.$route.params.event_uuid, this.$route.params.participant_uuid)
  }

  private async fetchScansByParticipant (event_uuid, participant_uuid) {
    try {
      this.$data.loading = true
      this.$data.table.items = await this.$store.dispatch('leads/fetchScansByParticipant', {
        event_uuid: event_uuid,
        participant_uuid: participant_uuid
      })
      if (this.$data.table.items.length) {
        this.$data.participant = this.$data.table.items[0].owner_short
      }
      this.$data.table.items.forEach((item) => {
        item.search_data = ''
        item.search_data += item.participant_short ? item.participant_short.company + ' ' : ''
        item.search_data += item.participant_short ? item.participant_short.first_name + ' ' : ''
        item.search_data += item.participant_short ? item.participant_short.last_name + ' ' : ''
        item.search_data += item.participant_short ? item.participant_short.email + ' ' : ''
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      await this.fetchQualifiersByParticipant(this.$route.params.event_uuid, this.$route.params.participant_uuid)
    }
  }

  private async fetchQualifiersByParticipant (event_uuid, participant_uuid) {
    try {
      this.$data.loading = true
      this.$data.qualifiers = await this.$store.dispatch('leads/getQualifiersByParticipant', {
        event_uuid: event_uuid,
        participant_uuid: participant_uuid
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.qualifiers.forEach((item) => {
        this.$data.table.headers.push({ text: item.description, align: 'center', sortable: false, value: item.barcode, width: '100' })
        this.$data.table.items.forEach((item2) => {
          item2.qualifiers.forEach((qualifier) => {
            if (qualifier.barcode === item.barcode) {
              item2[item.barcode] = true
            }
          })
        })
      })
      this.$data.loading = false
    }
  }

  async getExportScansByParticipant () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportScansByParticipant',
        { event_uuid: this.$route.params.event_uuid, participant_uuid: this.$route.params.participant_uuid }
      )
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
